<template>
  <div>
    <v-sheet elevation="0" class="pa-4">
      <v-row dense>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="search"
            @click:clear="handleClearSearch()"
            @change="onSearchChange()"
            prepend-inner-icon="mdi-magnify"
            :label="__('pesquisar')"
            solo
            dense
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-col>
        <v-col class="text-right">
          <v-btn @click="loadItems()" icon>
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <SelectedColumnsButton
            v-model="selectedHeaders"
            :headers="headers"
            :defaultHeaders="[
              {
                text: this.__('lead'),
                value: 'identifier_code',
                fixed: true,
              },
              {
                text: this.__('tipo'),
                value: 'lead_type_description',
              },
              { text: this.__('subtipo'), value: 'subtype_id' },
              { text: this.__('status'), value: 'current_status_name' },
              { text: this.__('gestor'), value: 'service_manager_name' },
              { text: this.__('companhia'), value: 'insurer_name' },
            ]"
            storage-key="leads-columns"
          />
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item to="/leads/new" link>
                <v-list-item-icon>
                  <v-icon>mdi-plus</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    __("adicionar lead")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="handleOpenEpsImporter()" link>
                <v-list-item-icon>
                  <v-icon>mdi-cloud-download</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ __("importar da eps") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet elevation="0" class="py-2 px-4">
      <AdvancedFilterChips
        @click:filter="openFiltersDialog()"
        @click:item="handleClickUpdateFilter"
        @click:clear="clearAdvancedFilters()"
        @applyFilter="applyFilters"
        @removeFilter="removeStoredAdvancedFilter"
        :filters="advancedFilters"
        :storedFilters="storedAdvancedFilters"
      />
    </v-sheet>
    <div v-resize="onResize">
      <v-data-table
        v-if="options"
        :headers="selectedHeaders"
        :items="items"
        :height="height - 305"
        fixed-header
        :options="options"
        @update:options="onOptionsUpdate($event)"
        :server-items-length="totalItems"
        disable-pagination
        must-sort
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [5, 10, 15],
          'show-current-page': true,
          'show-first-last-page': true,
        }"
      >
        <template #item.identifier_code="{ item }">
          <router-link :to="`leads/${item.identifier_code}`">{{
            item.identifier_code
          }}</router-link>
        </template>

        <template #item.risk_location="{ item }">
          {{ item.country_area_name }}, {{ item.city_name }}
        </template>

        <template #item.subtype_id="{ item }">
          {{ item.subtype_description }}
        </template>

        <template #item.reopening_at="{ item }">
          {{ item.reopening_at_diff }}
        </template>
      </v-data-table>
    </div>

    <AdvancedFiltersTable
      ref="advancedFilters"
      :template="advancedFiltersTemplate"
      @apply="applyFilters"
      @filter_stored="handleFilterStored"
      @removeFilter="removeStoredAdvancedFilter"
      table-key="leads"
      :storedFilters="storedAdvancedFilters"
    />

    <EpsImportLeadDialog ref="eps_import_dialog" />
  </div>
</template>

<script>
import { firstWord } from "@/tools/string";
import data_table from "@/tools/data_table";
import AdvancedFiltersTable from "@/components/advancedFilters/AdvancedFiltersTable.vue";
import AdvancedFilterChips from "@/components/advancedFilters/AdvancedFilterChips";
import SelectedColumnsButton from "@/components/tables/SelectedColumnsButton.vue";
import EpsImportLeadDialog from "@/components/EPS/EpsImportLeadDialog.vue";
import { index as indexManagers } from "@/services/service_managers";
import { getLeadStatuses } from "@/services/lead";
import { index as indexLeadTypes } from "@/services/lead_types";
import { indexAll as indexSubtypes } from "@/services/subtypes/subtypes";
import { index as indexInsurers } from "@/services/insurers";
import { index as indexUnities } from "@/services/unities";
import { getExternalStatusesDistinct as indexExternalStatuses } from "@/services/external_statuses";
import { getAppraisals as indexApraisals } from "@/services/appraisals";
import {
  index as indexAdvancedFilters,
  destroy as destroyAdvancedFilter,
} from "@/services/advanced_filters";
import ToastsMixin from "@/mixins/ToastsMixin.vue";
import i18n from "@/mixins/i18n";

export default {
  components: {
    AdvancedFiltersTable,
    AdvancedFilterChips,
    SelectedColumnsButton,
    EpsImportLeadDialog,
  },

  mixins: [ToastsMixin, i18n],

  data() {
    return {
      i18n_prefix: "leads",
      selectedHeaders: [],

      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: this.__("Lead", "leads"),
          value: "identifier_code",
          fixed: true,
        },
        {
          text: this.__("tipo", "leads"),
          value: "lead_type_description",
        },
        {
          text: this.__("subtipo", "leads"),
          value: "subtype_id",
        },
        {
          text: this.__("status", "leads"),
          value: "current_status_name",
        },
        {
          text: this.__("gestor", "leads"),
          value: "service_manager_name",
        },
        {
          text: this.__("companhia", "leads"),
          value: "insurer_name",
        },
        {
          text: this.__("segurado", "leads"),
          value: "insured_name",
        },
        {
          text: this.__("unidade", "leads"),
          value: "unity_name",
        },
        {
          text: this.__("local de risco", "leads"),
          value: "risk_location",
        },
        {
          text: this.__("referência externa", "leads"),
          value: "external_reference",
        },
        {
          text: this.__("gestor companhia", "leads"),
          value: "manager",
        },
        {
          text: this.__("status externo", "leads"),
          value: "external_state",
        },
        {
          text: this.__("tipo de peritagem", "leads"),
          value: "is_traditional_appraisal",
        },
        {
          text: this.__("data da visita", "leads"),
          value: "visit_date",
        },
        {
          text: this.__("data da pesquisa", "leads"),
          value: "research_date",
        },
        {
          text: this.__("data da obra", "leads"),
          value: "construction_date",
        },
        {
          text: this.__("Data da ocorrência", "leads"),
          value: "occurrence_date",
        },
        {
          text: this.__("última mudança de status", "leads"),
          value: "status_updated_at",
        },
        {
          text: this.__("antiguidade", "leads"),
          value: "antiguity",
        },
        {
          text: this.__("data de recebimento", "leads"),
          value: "created_at",
        },
        {
          text: this.__("reabertura", "leads"),
          value: "reopening_at",
        },
        {
          text: this.__("dias após visita", "leads"),
          value: "visit_date_diff",
        },
      ],
      items: [],
      loading: false,

      totalItems: 0,
      options: null,

      height: 0,

      search: "",
      advancedFilters: [],
      advancedFiltersTemplate: [
        {
          id: "manager_ids",
          type: "server_items",
          label: this.__("gestores", "leads"),
          itemLoader: async () => (await indexManagers()).data,
          valueItemValue: "id",
          valueItemText: "name",
          valueItemMultiple: true,
        },
        {
          id: "current_status_ids",
          type: "server_items",
          label: this.__("status", "leads"),
          itemLoader: getLeadStatuses,
          valueItemValue: "id",
          valueItemText: "name",
          valueItemMultiple: true,
        },
        {
          id: "lead_type_ids",
          type: "server_items",
          label: this.__("tipo", "leads"),
          itemLoader: indexLeadTypes,
          valueItemValue: "id",
          valueItemText: "description",
          valueItemMultiple: true,
        },
        {
          id: "lead_subtype_ids",
          type: "server_items",
          label: this.__("subtipo", "leads"),
          itemLoader: async () => (await indexSubtypes()).data,
          valueItemValue: "id",
          valueItemText: "description",
          valueItemMultiple: true,
        },
        {
          id: "insurer_ids",
          type: "server_items",
          label: this.__("companhia", "leads"),
          itemLoader: indexInsurers,
          valueItemValue: "id",
          valueItemText: "name",
          valueItemMultiple: true,
        },
        {
          id: "participant",
          type: "text",
          label: this.__("participantes", "leads"),
        },
        {
          id: "unity_ids",
          type: "server_items",
          label: this.__("unidade", "leads"),
          itemLoader: indexUnities,
          valueItemValue: "id",
          valueItemText: "name",
          valueItemMultiple: true,
        },
        {
          id: "external_statuses",
          type: "server_items",
          label: this.__("status externo", "leads"),
          itemLoader: indexExternalStatuses,
          valueItemValue: "state",
          valueItemText: "state",
          valueItemMultiple: true,
        },
        {
          id: "is_traditional_appraisal",
          type: "server_items",
          label: this.__("peritagem", "leads"),
          itemLoader: indexApraisals,
          valueItemValue: "is_traditional_appraisal",
          valueItemText: "description",
          valueItemMultiple: false,
        },
        {
          id: "initial_date",
          type: "date",
          label: this.__("data de recebimento", "leads"),
          valueItemMultiple: true,
        },
        {
          id: "visit_date",
          type: "date",
          label: this.__("data da visita", "leads"),
          valueItemMultiple: true,
        },
        {
          id: "research_date",
          type: "date",
          label: this.__("data da pesquisa", "leads"),
          valueItemMultiple: true,
        },
        {
          id: "construction_date",
          type: "date",
          label: this.__("data da obra", "leads"),
          valueItemMultiple: true,
        },
        {
          id: "occurrence_date",
          type: "date",
          label: this.__("Data da ocorrência", "leads"),
          valueItemMultiple: true,
        },
      ],
      storedAdvancedFilters: [],
    };
  },

  methods: {
    handleOpenEpsImporter() {
      this.$refs.eps_import_dialog.open();
    },

    handleFilterStored(newFilter) {
      if (
        this.storedAdvancedFilters.find(
          (element) => element.id === newFilter.id
        )
      ) {
        this.storedAdvancedFilters = this.storedAdvancedFilters.map(
          (element) => {
            if (element.id === newFilter.id) {
              return newFilter;
            }

            return element;
          }
        );
      } else {
        this.storedAdvancedFilters.push(newFilter);
      }
    },

    async removeStoredAdvancedFilter(filter) {
      if (!confirm(this.__("tem certeza que deseja excluir o filtro?"))) {
        return;
      }

      await destroyAdvancedFilter(filter.id);
      this.storedAdvancedFilters = this.storedAdvancedFilters.filter(
        (item) => item.id !== filter.id
      );

      this.toast(this.__("filtro eliminado"));
    },

    async loadStoredAdvancedFilters() {
      this.storedAdvancedFilters = await indexAdvancedFilters("leads");
    },

    clearAdvancedFilters() {
      this.loadItems(
        {
          ...this.options,
          page: 1,
        },
        []
      );
    },

    applyFilters(filters) {
      this.loadItems(
        {
          ...this.options,
          page: 1,
        },
        filters
      );
      this.$refs.advancedFilters.close();
    },

    handleClickUpdateFilter(filter) {
      this.$refs.advancedFilters.openForEdit(this.advancedFilters, filter);
    },

    openFiltersDialog() {
      this.$refs.advancedFilters.open(this.advancedFilters);
    },

    handleUpdateOptions(options) {
      if (
        this.$route.query.page === options.page &&
        this.$route.query.itemsPerPage === options.itemsPerPage &&
        this.$route.query.sortBy === options.sortBy &&
        this.$route.query.sortDesc === options.sortDesc &&
        this.$route.query.search === options.search
      ) {
        return;
      }

      console.log("alterando rota", options);

      this.$router.replace({
        path: "/leads",
        query: options,
      });
    },

    onResize() {
      this.height = window.innerHeight;
    },

    onSearchChange() {
      this.options.page = 1;
      this.storeOptions(this.options);
      this.loadItems();
    },

    onOptionsUpdate(newOptions) {
      this.options = newOptions;
      this.storeOptions(this.options);
      this.loadItems();
    },

    async loadItems(options = null, advancedFilters = null) {
      if (advancedFilters === null) {
        advancedFilters = this.advancedFilters;
      }

      if (options === null) {
        options = this.options;
      } else {
        this.options = options;
      }

      this.loading = true;

      try {
        this.items = [];
        const response = await this.$http.post("/leads/index", {
          ...data_table.getParams(options),
          filter: this.search,
          advancedFilters,
        });

        this.items = response.data.data.map((item) => {
          return {
            ...item,
            insurer_name: firstWord(item.insurer_name),
          };
        });
        this.totalItems = response.data.meta.total;
        if (response.data.meta.advancedFilters) {
          this.advancedFilters = response.data.meta.advancedFilters;
        }

        this.options = {
          ...options,
        };

        this.storeOptions(options);
        this.storeAdvancedFilters(advancedFilters);
        this.storeSearch(this.search);
      } catch (e) {
        console.error(e);
        alert(e);
      } finally {
        this.loading = false;
      }
    },

    storeOptions(options) {
      localStorage.setItem("leads-options", JSON.stringify(options));
    },

    loadOptions() {
      const options = localStorage.getItem("leads-options");
      if (!options) {
        return data_table.getOptions({});
      }

      return data_table.getOptions(JSON.parse(options));
    },

    storeSearch(search) {
      if (!search) {
        search = "";
      }

      localStorage.setItem("leads-search", search);
    },

    loadSearch() {
      const search = localStorage.getItem("leads-search");
      if (!search) {
        return "";
      }

      return search;
    },

    storeAdvancedFilters(advancedFilters) {
      localStorage.setItem(
        "leads-advanced-filters",
        JSON.stringify(advancedFilters)
      );
    },

    loadAdvancedFilters() {
      const filters = localStorage.getItem("leads-advanced-filters");
      if (!filters) {
        return [];
      }

      return JSON.parse(filters);
    },

    getParamArray(object, key, defaultValue) {
      console.log(object);

      if (object[key] === undefined) {
        return defaultValue;
      }

      if (Array.isArray(object[key])) {
        if (object[key].length === 0) {
          return defaultValue;
        }

        return object[key];
      }

      return [object[key]];
    },

    handleClearSearch() {
      this.search = "";
      this.loadItems(this.options, this.advancedFilters);
    },
  },

  watch: {
    options: {
      handler(value) {
        console.log(value);
        if (!value) {
          return;
        }

        // this.loadItems(value);
      },
      immediate: true,
    },
  },

  created() {
    this.advancedFilters = this.loadAdvancedFilters();
    this.search = this.loadSearch();
    this.options = this.loadOptions();
    this.loadStoredAdvancedFilters();
  },
};
</script>
