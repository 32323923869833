<template>
  <v-dialog v-model="dialog" width="400" persistent>
    <v-form @submit.prevent="submit">
      <v-card>
        <v-card-title>
          {{ __("filtro") }}
          <v-spacer></v-spacer>
          <v-btn @click="close()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-autocomplete
            v-model="form.column"
            @input="handleColumnUpdate"
            :label="__('coluna')"
            :items="template"
            item-text="label"
            item-value="id"
            returnObject
            outlined
            dense
          ></v-autocomplete>

          <div v-if="form.column && form.column.type === 'server_items'">
            <v-autocomplete
              v-model="form.value"
              return-object
              :label="__('valor')"
              outlined
              dense
              :loading="valueLoading"
              :items="valueItems"
              :multiple="form.column.valueItemMultiple"
              :item-value="form.column.valueItemValue"
              :item-text="form.column.valueItemText"
              clearable
              @focus="focused = true"
              @blur="focused = false"
              :placeholder="__('digite para pesquisar')"
            >
              <template v-slot:selection="data">
                <div v-if="form.column.valueItemMultiple">
                  <span v-if="focused"></span>
                  <v-chip
                    v-else-if="form.value.length < 3"
                    v-bind="data.attrs"
                    small
                    label
                  >
                    {{ data.item[form.column.valueItemText] }}
                  </v-chip>
                  <div v-else-if="data.index === 0">
                    {{ `${form.value.length} ` }}{{ __("itens carregados") }}
                  </div>
                </div>
                <div v-else>
                  {{ data.item[form.column.valueItemText] }}
                </div>
              </template>

              <template #prepend-item>
                <v-list-item
                  @click="handleSelectAll()"
                  :class="{
                    'primary--text': allSelected,
                    'v-list-item--active': allSelected,
                  }"
                  v-if="form.column.valueItemMultiple"
                >
                  <v-list-item-action>
                    <v-simple-checkbox
                      @click="handleSelectAll()"
                      :value="allSelected"
                      readonly
                      color="primary"
                      :ripple="false"
                    ></v-simple-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ __("carregar todos") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </div>
          <div v-else-if="form.column && form.column.type === 'text'">
            <v-text-field
              v-model="form.value"
              :label="__('valor')"
              outlined
              dense
            ></v-text-field>
          </div>
          <div v-else-if="form.column && form.column.type === 'date'">
            <v-text-field
              v-model="form.value_start"
              :label="__('valor inicial')"
              type="date"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.value_end"
              :label="`${__('valor final')} ${'('} ${__('opcional')} ${')'}`"
              type="date"
              outlined
              dense
            ></v-text-field>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn @click="close()" text>{{ __("cancelar") }}</v-btn>
          <v-btn type="submit" color="primary">{{ __("aplicar") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { getFilterValueStringResumed } from "@/tools/advanced_filters";
import i18n from "@/mixins/i18n";

export default {
  components: {},

  props: {
    template: Array,
  },

  mixins: [i18n],
  data() {
    return {
      i18n_prefix: "advanced_filters",
      dialog: false,
      form: {},

      valueItems: [],
      valueLoading: false,

      getFilterValueStringResumed,

      focused: false,
    };
  },

  methods: {
    handleSelectAll() {
      if (this.allSelected) {
        this.form.value = [];
        return;
      } else {
        this.form.value = [...this.valueItems];
      }
    },

    open(filter = null) {
      this.dialog = true;

      if (filter === null) {
        this.reset();
      } else {
        this.form = { ...filter };
        this.handleColumnUpdate({ ...filter.column }, false);
      }
    },

    close() {
      this.dialog = false;
    },

    reset() {
      this.form = {
        column: null,
        value: null,
      };
      this.valueItems = [];
    },

    submit() {
      this.$emit("submit", {
        ...this.form,
        value: this.prepareValue(),
      });
      this.close();
    },

    prepareValue() {
      if (!this.form.column.valueItemValue) {
        return this.form.value;
      }

      if (!Array.isArray(this.form.value)) {
        return {
          [this.form.column.valueItemValue]:
            this.form.value[this.form.column.valueItemValue],
          [this.form.column.valueItemText]:
            this.form.value[this.form.column.valueItemText],
        };
      }

      return this.form.value.map((item) => {
        return {
          [this.form.column.valueItemValue]:
            item[this.form.column.valueItemValue],
          [this.form.column.valueItemText]:
            item[this.form.column.valueItemText],
        };
      });
    },

    handleColumnUpdate(column, resetValue = true) {
      if (column.type === "server_items") {
        this.$nextTick().then(() => {
          if (resetValue) {
            this.form.value = null;
          }
        });

        this.loadItems(column);
        return;
      }

      if (column.type === "date") {
        this.$nextTick().then(() => {
          if (resetValue) {
            this.form.value_start = null;
            this.form.value_end = null;
          }
        });
        return;
      }
    },

    async loadItems(column) {
      if (!column.itemLoader) {
        return;
      }

      this.valueItems = [];

      this.valueLoading = true;
      try {
        this.valueItems = await column.itemLoader();
      } catch (error) {
        console.error(error);
        alert("Falha ao carregar itens");
      } finally {
        this.valueLoading = false;
      }
    },
  },

  computed: {
    allSelected() {
      if (!this.form) {
        return false;
      }

      if (!Array.isArray(this.form.value)) {
        return false;
      }

      if (!Array.isArray(this.valueItems)) {
        return false;
      }

      return this.valueItems.length === this.form.value.length;
    },
  },
};
</script>
